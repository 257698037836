import {
  AppBar,
  createStyles,
  makeStyles,
  Toolbar,
  Button,
} from "@material-ui/core";
import { Button as GatsbyMuiButton } from "gatsby-theme-material-ui";
import React from "react";
//@ts-ignore
import appIcon from "../images/icon.svg";
import { base_external } from "../api/xrp";

const useStyles = makeStyles(theme =>
  createStyles({
    separator: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
  })
);

export const Navigation: React.FC = () => {
  const styles = useStyles();
  return (
    <AppBar
      component="nav"
      position="sticky"
      variant="outlined"
      color="inherit"
    >
      <Toolbar>
        <div className={styles.separator}>
          <img src={appIcon} height={56} />
        </div>
        <GatsbyMuiButton to="/" size="large">
          Home
        </GatsbyMuiButton>
        {/* <GatsbyMuiButton to="/enterprise" size="large">
          Enterprise
        </GatsbyMuiButton> */}
        <GatsbyMuiButton to="/faq" size="large">
          FAQ
        </GatsbyMuiButton>
        <Button
          href={`${base_external}/auth/login`}
          size="large"
          target="_blank"
          title="Sign in"
        >
          Sign in
        </Button>
        <Button
          href={`${base_external}/auth/signup`}
          size="large"
          target="_blank"
          title="Sign up"
        >
          Sign up
        </Button>
      </Toolbar>
    </AppBar>
  );
};
